import { post } from '../axios'

interface Params extends Record<string, unknown> {
  receiver_phone_number: string
  flow_code: string
  communication_channels: string[]
  lang: string
  template_data: PaymentMessageTemplate
}

interface PaymentMessageTemplate extends Record<string, unknown> {
  payment_link: string
}

export const createSharePaymentLinkRequest = (
  phoneNumber: string,
  lang: string,
  paymentSessionUrl: string,
): Params => {
  return {
    receiver_phone_number: phoneNumber,
    flow_code: 'SHARE_PARTIAL_PAYMENT_LINK',
    communication_channels: ['whatsapp'],
    lang: lang,
    template_data: {
      payment_link: paymentSessionUrl,
    },
  }
}

const sharePaymentLinkApi = async (params: Params) =>
  post(`common/v1/send/communications`, params)

export default sharePaymentLinkApi
