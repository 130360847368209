import Link from 'next/link';
import { useRouter } from 'next/router';
import { PropsWithChildren, useMemo } from 'react';
import NavigateHome from '@/components/icons/NavigateHome';
import { NAVIGATION_FROM_APP, NAVIGATION_FROM_APP_CODE } from '@/constants/common';
import useIsMobile from '@/hooks/useIsMobile';
import { getCookieValue, isDigiAcreUser } from '@/utils/helper';
import AppBackButton from './AppBackButton';
import CartButton from './cart/MyCart';
import Profile from './navigation/Profile';
import WhatsAppShare from './WhatsAppShare';
interface Props {
  gaEventData?: string;
  heading: string;
  onBackClick?: VoidFunction;
  showHomeIcon?: boolean;
  showProfileIcon?: boolean;
  showBackButton?: boolean;
  showCart?: boolean;
  showWhatsAppIcon?: boolean;
  whatsappShareMessage?: string;
  homePath?: string;
  headingStyle?: string;
}
const HeaderWithBackButton = ({
  children,
  gaEventData,
  heading,
  onBackClick,
  showHomeIcon = true,
  showProfileIcon = true,
  showBackButton = true,
  showCart = false,
  showWhatsAppIcon = false,
  whatsappShareMessage,
  homePath = '/',
  headingStyle = 'grow text-neutral-100 pl-4 sm:p-0 sm:text-center'
}: PropsWithChildren<Props>) => {
  const isDAUser = isDigiAcreUser();
  const fromApp = useMemo(() => Boolean(getCookieValue(NAVIGATION_FROM_APP)) || Boolean(getCookieValue(NAVIGATION_FROM_APP_CODE)) || isDAUser, []);
  const {
    isMobile,
    isWindowLoading
  } = useIsMobile();
  return <div data-sentry-component="HeaderWithBackButton" data-sentry-source-file="HeaderWithBackButton.tsx">
      <header className={`bg-white px-4 flex items-center shadow-[0_6px_20px_rgba(0,0,0,0.05)] fixed top-0 w-full left-0 z-50 ${!showProfileIcon || fromApp && !isDAUser ? 'py-4' : 'pb-2 pt-2.5'}`}>
        {showBackButton && <AppBackButton gaEventData={gaEventData} onBackClick={onBackClick} />}
        {showHomeIcon && !fromApp && <Link href={homePath}>
            <NavigateHome className="ml-4 h-6 fill-primary-100" aria-label="Home" />
          </Link>}
        <h5 className={headingStyle}>{heading}</h5>
        {children}

        {showCart && !isWindowLoading && !isMobile && <CartButton />}
        {showProfileIcon && !fromApp && <Profile />}
        {showWhatsAppIcon && <WhatsAppShare message={(whatsappShareMessage as string)} />}
      </header>
    </div>;
};
export default HeaderWithBackButton;