import validateOrderV2 from '@dehaat/kisan-app-bl/api/validateOrderV2'
import { Address } from '@dehaat/kisan-app-bl/models/AddressV2'
import { Error, PaymentOptions } from '@dehaat/kisan-app-bl/models/Order'
import { Vendor } from '@dehaat/kisan-app-bl/models/Vendor'
import { isErrorCodeFromCart } from '@dehaat/kisan-app-bl/utils/cart'
import { AxiosError } from 'axios'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useContext, useEffect, useState } from 'react'

import { AVAILABLE_STOCK, MY_CART } from '@/constants/common'
import { ORDER_STATUS } from '@/constants/order'
import { HYP_PAYMENT_MODE } from '@/constants/payments'
import { CartContext } from '@/context/CartProvider'
import { InsuranceContext } from '@/context/InsuranceProvider'
import { UserContext } from '@/context/UserProvider'
import { InsuranceDataItem } from '@/models/api/productInsurance'
import { AvailbleStockError, CartValidationError, OTPData } from '@/models/Cart'
import { AvailableCoupon } from '@/models/Coupon'
import { ValidateOrder } from '@/models/Order'
import { CartStateInStorage, ProductVariant } from '@/models/Product'
import { createOrderQuery } from '@/utils/cart'
import {
  getLocalStorageKey,
  isArray,
  isSessionStorageAvailable,
} from '@/utils/helper'

const useCartValidation = () => {
  const [validating, setValidating] = useState(false)
  const [validationError, setValidationError] =
    useState<CartValidationError | null>(null)
  const [errorCode, setErrorCode] = useState<string>()
  const [availableCoupons, setAvailableCoupons] = useState<AvailableCoupon[]>(
    [],
  )
  const [otpData, setOTPData] = useState<OTPData>()
  const [isPayNowEnabled, setIsPayNowEnabled] = useState(false)
  const [prePayAmount, setPrePayAmount] = useState(NaN)
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptions>()
  const [appliedCoupon, setAppliedCoupon] = useState<AvailableCoupon | null>(
    null,
  )
  const [cumulative_commission, setCumulativeCommission] = useState<number>(0)

  const { cartId, cartItems, cartType, isPickupOrder, pickupFarmerDetails } =
    useContext(CartContext)
  const { insuranceItems } = useContext(InsuranceContext)
  const { user } = useContext(UserContext)
  const isPrebookOrder = getLocalStorageKey<CartStateInStorage, null>(
    MY_CART,
    null,
  )?.[cartType].isPrebook
  const { t } = useTranslation('cartInsurance')

  const validateCart = useCallback(
    async (
      selectedAddress: Address | null,
      vendor: Vendor,
      validateWithCoupon: boolean,
      appliedCoupon: AvailableCoupon | null,
      variants: ProductVariant[],
      idKycStatus: boolean,
      bankKycStatus: boolean,
      totalVariantCost: number,
      totalInsuranceCost: number,
      totalDiscount: number,
      shippingCharges: number,
      insuranceData: InsuranceDataItem[],
      isCreditOrder: boolean,
    ) => {
      setValidating(true)
      const query = createOrderQuery(
        isPickupOrder,
        (isPickupOrder
          ? pickupFarmerDetails?.phone_number
          : user?.phone_number) || '',
        cartId,
        variants,
        totalVariantCost,
        totalInsuranceCost,
        totalDiscount,
        shippingCharges,
        validateWithCoupon,
        cartItems,
        appliedCoupon,
        selectedAddress,
        insuranceItems,
        insuranceData,
        idKycStatus,
        bankKycStatus,
        '',
        vendor,
        '',
        false,
        0,
        isCreditOrder,
        isCreditOrder ? HYP_PAYMENT_MODE.CREDIT : HYP_PAYMENT_MODE.CASH,
      )

      if (isPrebookOrder) {
        query.status = ORDER_STATUS.PreOrder
      }

      try {
        const response = await validateOrderV2(query)
        if (response) {
          const {
            coupons,
            pay_now,
            payment_options,
            prepay_amount,
            cumulative_commission,
            is_otp_required,
            otp_template_variables,
          } = response as ValidateOrder
          setOTPData({
            required: is_otp_required,
            template: otp_template_variables,
          })
          setIsPayNowEnabled(pay_now)
          setPaymentOptions(payment_options)
          setPrePayAmount(prepay_amount || NaN)
          setCumulativeCommission(cumulative_commission || 0)
          setValidationError(null) // Clear validation error if revalidation gives response
          if (coupons) {
            setAvailableCoupons(coupons)
          }
        }
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error: any = (e as AxiosError).response?.data

        // Handle error when available quantity at vendor's end is less than selected quantity
        if (error?.non_field_errors) {
          try {
            const availableQuantities: Record<string, number> = {}
            const errorArr: AvailbleStockError[] = JSON.parse(
              error.non_field_errors,
            )

            errorArr.forEach((item) => {
              const key = item.item_id
              availableQuantities[key] = item.available_qty
            })

            setErrorCode('item_quantity_error')
            setValidationError({ message: JSON.stringify(availableQuantities) })
            if (isSessionStorageAvailable(window)) {
              const availableQtyInSS = sessionStorage.getItem(AVAILABLE_STOCK)

              sessionStorage.setItem(
                AVAILABLE_STOCK,

                JSON.stringify(
                  availableQtyInSS
                    ? {
                        ...JSON.parse(availableQtyInSS),
                        ...availableQuantities,
                      }
                    : availableQuantities,
                ),
              )
            }
          } catch {
            // If error could not be parsed than it's most likely a simple error message
            setValidationError({ message: error.non_field_errors[0] })
          }
        }
        const productInactiveError: Error = error?.error
        if (
          productInactiveError &&
          productInactiveError.code &&
          isErrorCodeFromCart(productInactiveError.code)
        ) {
          setErrorCode(productInactiveError.code)
          setValidationError({
            message: productInactiveError.message,
            details: productInactiveError.details,
          })
        } else {
          const errorCode = (e as Error)?.code
          if (errorCode && isErrorCodeFromCart(errorCode)) {
            setErrorCode((e as Error).code)
            setValidationError({
              message: (e as Error).message,
              details: (e as Error).details,
            })
          } else if (error?.key && error.key === 'DC_QUOTA_EXHAUSTED') {
            setValidationError({
              message: t('dc_quota_error', {
                quota: error.value,
              }),
            })
          } else if (error?.insurance_type) {
            setValidationError({
              message: t('cart_amt_error', {
                minimum: error.cart_threshold_data.minimum_cart_value,
                maximum: error.cart_threshold_data.maximum_cart_value,
              }),
            })
          } else if (error?.detail) {
            setValidationError({ message: error.detail })
          }
        }
      }
      setValidating(false)
    },
    [
      cartId,
      cartItems,
      insuranceItems,
      isPickupOrder,
      pickupFarmerDetails,
      t,
      user,
      isPrebookOrder,
    ],
  )

  // Apply available coupon
  useEffect(() => {
    if (isArray(availableCoupons) && appliedCoupon === null) {
      setAppliedCoupon(
        availableCoupons.find((coupon) => coupon.is_applied) || null,
      )
    }
    // Do not add appliedCoupon in the dependencies as we don't want coupon to get automatically set when user removes it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCoupons])

  return {
    appliedCoupon,
    availableCoupons,
    setAppliedCoupon,
    setValidationError,
    validateCart,
    validating,
    validationError,
    isPayNowEnabled,
    paymentOptions,
    prePayAmount,
    errorCode,
    cumulative_commission,
    otpData,
  }
}

export default useCartValidation
