import fetchOrderDetailsV3 from '@dehaat/kisan-app-bl/api/fetchOrderDetailsV3';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ORDER_SUCCESS_DISPLAY_DURATION, PAYMENT_STATUS_POLLING_INTERVAL, PENDING_PAYMENT_DISPLAY_DURATION } from '@/constants/cart';
import { NAVIGATION_FROM_APP, NAVIGATION_FROM_APP_CODE } from '@/constants/common';
import { ORDER_STATUS } from '@/constants/order';
import { CartContext } from '@/context/CartProvider';
import SuccesGif from '@/images/payments/Done.gif';
import PaymentPendingGif from '@/images/payments/Rupee.gif';
import { getCookieValue } from '@/utils/helper';
interface OrderStatusProps {
  orderId?: string;
  isOnlineOrder: boolean;
}
export default function OrderStatusPopup({
  orderId,
  isOnlineOrder
}: OrderStatusProps) {
  const [status, setStatus] = useState<'pending' | 'success'>('pending');
  const {
    push,
    asPath,
    replace,
    locale
  } = useRouter();
  const fromApp = useMemo(() => Boolean(getCookieValue(NAVIGATION_FROM_APP)) || Boolean(getCookieValue(NAVIGATION_FROM_APP_CODE)), []);
  const redirectUrl = fromApp ? '/orders?sh=f' : '/orders';
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const {
    resetCart
  } = useContext(CartContext);
  const redirectToOrderListing = useCallback(() => {
    if (asPath.includes('/cart')) {
      replace(redirectUrl).then(() => resetCart());
    } else {
      push(redirectUrl).then(() => resetCart());
    }
  }, [asPath, push, redirectUrl, replace, resetCart]);
  const clearTimer = useCallback(() => {
    if (intervalId.current) clearInterval(intervalId.current);
    if (timeoutId.current) clearTimeout(timeoutId.current);
  }, []);
  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        const orderDetails = await fetchOrderDetailsV3((orderId as string), locale);
        const ispaymentSuccess = orderDetails.online_payment_order && orderDetails?.online_payment.payment_status && orderDetails?.online_payment.payment_status !== ORDER_STATUS.Pending;
        if (ispaymentSuccess) {
          setStatus('success');
          if (intervalId.current) clearInterval(intervalId.current);
          if (timeoutId.current) clearTimeout(timeoutId.current);
          setTimeout(() => {
            redirectToOrderListing();
          }, ORDER_SUCCESS_DISPLAY_DURATION);
        }
      } catch (err) {
        console.error('Failed to fetch order status:', err);
        setStatus('pending');
        clearTimer();
      }
    };
    if (isOnlineOrder) {
      if (orderId) {
        intervalId.current = setInterval(fetchOrderStatus, PAYMENT_STATUS_POLLING_INTERVAL);
        timeoutId.current = setTimeout(() => {
          if (intervalId.current) clearInterval(intervalId.current);
          redirectToOrderListing();
        }, PENDING_PAYMENT_DISPLAY_DURATION);
      }
    } else {
      timeoutId.current = setTimeout(() => {
        redirectToOrderListing();
      }, ORDER_SUCCESS_DISPLAY_DURATION);
    }
    return () => {
      clearTimer();
    };
  }, [clearTimer, isOnlineOrder, locale, orderId, push, redirectToOrderListing, status]);
  if (status === 'success' || !isOnlineOrder) {
    return <div className="fixed inset-0 z-50 bg-white flex flex-col justify-center items-center bg-primary-100 p-4">
        <Image src={SuccesGif} alt="payment-sccess" className="max-w-sm" />

        <div className="text-lg mt-4 text-white font-semibold">
          Order Placed Successfully
        </div>
      </div>;
  }
  return <div className="fixed inset-0 z-50 bg-white flex flex-col justify-center items-center bg-[#FFF3C2] p-4" data-sentry-component="OrderStatusPopup" data-sentry-source-file="OrderStatusPopup.tsx">
      <Image src={PaymentPendingGif} alt="payment-pending" className="max-w-sm" data-sentry-element="Image" data-sentry-source-file="OrderStatusPopup.tsx" />
      <div className="mt-4 text-lg text-[#8C6500] font-semibold">
        Waiting for Payment
      </div>
    </div>;
}