import { Button, BUTTON_TYPE, COMMON_STYLES, RadioButton } from '@dehaat/dds';
import formatCurrency from '@dehaat/kisan-app-bl/helpers/formatCurrency';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useMemo, useState } from 'react';
import useErrorModalState from '@/hooks/useErrorModalState';
import { validateDigitOnly } from '@/utils/validation';
import AppBackButton from '../AppBackButton';
import ErrorModal from '../ErrorModal';
import SharePaymentLinkSheet from './SharePaymentLinkSkheet';
interface Props {
  minimumAmount: number;
  maximumAmount: number;
  show: boolean;
  onClose: VoidFunction;
  handleProceedClick: (amount: number) => void;
  handlePaymentLinkShare: (amount: number, number: string) => void;
}
const ChooseAmountDrawer = ({
  minimumAmount,
  maximumAmount,
  show,
  onClose,
  handleProceedClick,
  handlePaymentLinkShare
}: Props) => {
  const {
    t
  } = useTranslation('payments');
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [customAmount, setCustomAmount] = useState<string>();
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [amountRangeError, setAmountRangeError] = useState(false);
  const isCustomAmountValid = useMemo(() => Number(customAmount) > 0 && Number(customAmount) < maximumAmount, [customAmount, maximumAmount]);
  const handleConfirmClick = () => {
    if (selectedOption === 0) {
      handleProceedClick(maximumAmount);
    } else if (minimumAmount > 0) {
      handleProceedClick(minimumAmount);
    } else if (isCustomAmountValid) {
      handleProceedClick(Number(customAmount));
    } else {
      console.log('something went wrong');
    }
  };
  const handleInputChange = (amount: string) => {
    if (validateDigitOnly(amount) && Number(amount) <= maximumAmount && Number(amount) > 0 || amount == '') {
      setAmountRangeError(false);
      setCustomAmount(amount);
    } else {
      setAmountRangeError(true);
      return;
    }
  };
  const resolveTargetAmount = (): number => {
    if (selectedOption === 1) {
      if (minimumAmount > 0) {
        return minimumAmount;
      } else if (isCustomAmountValid) {
        return Number(customAmount);
      }
    } else {
      return maximumAmount;
    }
    return 0;
  };
  const sharePaymentLink = async (number: string) => {
    handlePaymentLinkShare(resolveTargetAmount(), number);
  };
  return show ? <div className="fixed inset-0 z-50 bg-white flex flex-col" data-sentry-component="ChooseAmountDrawer" data-sentry-source-file="ChooseAmountDrawer.tsx">
      <div className="flex items-center p-4 bg-gray-100 border-b border-gray-300">
        <AppBackButton onBackClick={onClose} data-sentry-element="AppBackButton" data-sentry-source-file="ChooseAmountDrawer.tsx" />
        <h2 className="text-lg font-bold ml-4">{t('choose_amount')}</h2>
      </div>

      {/* Content */}
      <div className="flex-grow p-4 mt-4">
        {/* Radio Buttons */}
        <div className="flex flex-col gap-4">
          {[{
          label: t('pay_full_order_amount'),
          value: 0,
          amount: maximumAmount
        }, {
          label: minimumAmount > 0 ? t('pay_minimum_amount') : t('pay_partial_amount'),
          value: 1,
          amount: minimumAmount
        }].map(({
          label,
          value,
          amount
        }, index) => <div key={value} className={`border rounded-xl p-4 flex items-center gap-3 cursor-pointer ${selectedOption === value ? 'border-[#0E7A41] bg-white' : 'border-gray-300 bg-white'}`} onClick={() => {
          setSelectedOption(value);
          if (value === 1 && minimumAmount === 0) {
            setCustomAmount('');
          }
        }}>
              <div className={`w-5 h-5 flex justify-center items-center rounded-full border-2 ${selectedOption === value ? 'border-[#0E7A41] bg-white' : 'border-[#0E7A41] bg-white'}`}>
                {selectedOption === value && <div className="w-2.5 h-2.5 rounded-full bg-[#0E7A41]"></div>}
              </div>
              <div className="flex-grow">
                <p className="text-sm font-normal font-color-[#33393D]">
                  {label}
                </p>
                {value === 0 || minimumAmount > 0 ? <p className="font-nato text-xl">{formatCurrency(amount)}</p> : <div className="relative w-full" style={{
              display: 'flex',
              alignItems: 'center'
            }}>
                    <span style={{
                position: 'absolute',
                left: '15px',
                color: '#000000',
                fontSize: '16px',
                top: '50%',
                lineHeight: '1',
                transform: 'translateY(-40%)'
              }}>
                      ₹
                    </span>
                    <input type="text" className={`border border-solid rounded-lg p-2 mt-1 w-full text-base pl-8 ${selectedOption !== 1 ? 'bg-gray-100' : ''}`} value={customAmount} onChange={e => handleInputChange(e.target.value)} placeholder={t('enter_custom_amount')} disabled={selectedOption !== 1} />
                  </div>}
                {index === 1 ? amountRangeError ? <p className="text-sm text-red-500 mt-1">
                      {t('invalid_custom_amount', {
                amount: maximumAmount
              })}
                    </p> : selectedOption === 1 && customAmount && <div className="text-xs mt-1 text-neutral-80">
                        {`Please pay ₹${maximumAmount - Number(customAmount)} at the time of delivery.`}
                      </div> : null}
              </div>
            </div>)}
        </div>

        {/* Buttons with Shadow */}
        <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg" style={{
        boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)'
      }}>
          <div className="mx-4 mt-4 flex flex-col gap-2">
            <Button text={t('confirm_and_proceed')} type={BUTTON_TYPE.PRIMARY} buttonStyle={[COMMON_STYLES['mt1/2'], COMMON_STYLES['pv3/2']]} onClick={() => {
            handleConfirmClick();
          }} data-sentry-element="Button" data-sentry-source-file="ChooseAmountDrawer.tsx" />
            <Button text={t('share_payment_link')} type={BUTTON_TYPE.SECONDARY} buttonStyle={[COMMON_STYLES['mt1/2'], COMMON_STYLES['pv3/2'], COMMON_STYLES['mb1/2']]} onClick={() => setBottomSheetOpen(true)} data-sentry-element="Button" data-sentry-source-file="ChooseAmountDrawer.tsx" />
          </div>
        </div>
      </div>

      {isBottomSheetOpen && <SharePaymentLinkSheet onClose={() => setBottomSheetOpen(false)} onSubmit={(phoneNumber: string) => {
      sharePaymentLink(phoneNumber);
    }} />}
    </div> : null;
};
export default ChooseAmountDrawer;