import { useContext } from 'react'

import {
  NAVIGATION_FROM_APP,
  NAVIGATION_FROM_APP_CODE,
} from '@/constants/common'
import { UserContext } from '@/context/UserProvider'
import { APP_CODE_TYPE } from '@/models/Auth'
import CustomEventTypes from '@/utils/analytics/customEventTypes'
import Mixpanel from '@/utils/analytics/mixpanel'
import { getClientName } from '@/utils/external'
import { getCookieValue, isDigiAcreUser, isValidJSON } from '@/utils/helper'
import getClientCode from '@/utils/helpers/getClientCode'
import { isMoengageIntialized } from '@/utils/moengage'

const useTriggerEvents = () => {
  const { user } = useContext(UserContext)

  const trackCustomEvent = (
    eventType: CustomEventTypes,
    params?: string,
    productName?: string,
  ) => {
    const utmParams = sessionStorage.getItem('utm_params')
    let extraParams: Record<string, any> = {}
    if (window.gtag != null) {
      let eventParams: Record<string, string> = {
        page_location: window.location.href,
        client_src: getClientName(),
        is_ondc: window.location.pathname.includes('/ondc') ? '1' : '0',
      }
      let eventName = `${eventType}`
      if (params) {
        eventParams = { ...eventParams, event_params: params }
      }
      if (utmParams) {
        eventParams = { ...eventParams, ...JSON.parse(utmParams) }
      }
      if (productName) {
        eventName = `${eventType}/${productName}`
      }
      window.gtag('event', eventName, eventParams)

      // Mixpanel track event
      if (user) {
        extraParams = {
          UserMobile: user.phone_number,
          UserName: user.full_name,
          UserLocation: user.location?.location?.coordinates,
          UserProfileSource: user.profile?.source,
          UserProfieSourceId: user.profile?.id,
          UserExternalPartnerID: user?.external_partner_id,
          UserExternalPartnerType: user?.external_partner_type,
          UserState: user.location?.state.name,
          UserDistrict: user.location?.district.name,
          UserBlock: user.location?.block.name,
          UserEmail: user.email,
          AppSource: getClientCode(
            (getCookieValue(NAVIGATION_FROM_APP_CODE) as APP_CODE_TYPE) ||
              Boolean(getCookieValue(NAVIGATION_FROM_APP)),
          ),
        }
      }
      if (params && isValidJSON(params)) {
        extraParams = { ...extraParams, ...JSON.parse(params) }
      }
      //track event on Moengage as well so that users can be filtered based on this data
      if (isMoengageIntialized()) {
        Moengage.track_event(eventName, {
          ...eventParams,
          ...extraParams,
        })
      }
      Mixpanel.track(eventName, {
        ...eventParams,
        ...extraParams,
      })
    }
  }

  return {
    trackCustomEvent,
  }
}

export default useTriggerEvents
