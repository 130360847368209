import { Button, COMMON_STYLES } from '@dehaat/dds';
import useTranslation from 'next-translate/useTranslation';
import { useMemo, useState } from 'react';
interface SharePaymentLinkSheetProps {
  onClose: VoidFunction;
  onSubmit: (phoneNumber: string) => void;
}
const SharePaymentLinkSheet = ({
  onClose,
  onSubmit
}: SharePaymentLinkSheetProps) => {
  const {
    t
  } = useTranslation('payments');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const isPhoneNumberValid = useMemo(() => phoneNumber.length === 10 && /^\d+$/.test(phoneNumber), [phoneNumber]);
  return <div className="fixed inset-0 z-50" data-sentry-component="SharePaymentLinkSheet" data-sentry-source-file="SharePaymentLinkSkheet.tsx">
      {/* Dim Background */}
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose} />

      {/* Bottom Sheet */}
      <div className="absolute bottom-0 inset-x-0 bg-white p-4 shadow-lg z-50 rounded-t-2xl">
        {/* Shadow on Upper Edge */}
        <div className="h-1 w-12 bg-gray-300 rounded-full mx-auto mb-4"></div>

        {/* Header */}
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-bold">{t('enter_phone_number')}</h2>
          <button onClick={onClose} className="w-8 h-8 flex items-center justify-center bg-[#E6EBF0] rounded-full text-lg">
            ✕
          </button>
        </div>

        {/* Input Field */}
        <div className="mt-4">
          <div className="flex items-stretch border rounded overflow-hidden">
            {/* Prefix */}
            <div className="flex items-center justify-center bg-[#E6EBF0] px-3">
              <span className="text-sm text-gray-700 font-medium">+91</span>
            </div>
            {/* Input */}
            <input type="tel" className="flex-1 p-2 outline-none" placeholder={t('enter_phone_number')} value={phoneNumber} maxLength={10} onChange={e => setPhoneNumber(e.target.value)} />
          </div>
          {/* Validation Error */}
          {showError && !isPhoneNumberValid && phoneNumber.length > 0 && <div className="mt-2">
              <p className="text-sm text-red-500">
                {t('invalid_phone_number')}
              </p>
            </div>}
        </div>

        {/* Button */}
        <Button text={t('confirm_and_proceed')} buttonStyle={[COMMON_STYLES.mt1, COMMON_STYLES['pv3/2']]} onClick={() => {
        if (isPhoneNumberValid) {
          setShowError(false);
          onSubmit(phoneNumber);
        } else {
          setShowError(true);
        }
      }} data-sentry-element="Button" data-sentry-source-file="SharePaymentLinkSkheet.tsx" />
      </div>
    </div>;
};
export default SharePaymentLinkSheet;