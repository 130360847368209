import { Button, BUTTON_STATUS, COLORS, RadioButton, SIZE } from '@dehaat/dds';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement, useCallback, useContext, useState } from 'react';
import { PAYMENT_OPTIONS, paymentDrawerData } from '@/constants/payments';
import { UserContext } from '@/context/UserProvider';
import CreditLabel from '../credit/CreditLabel';
import RoundedBottomDrawer from '../RoundedBottomDrawer';
interface Props {
  maximumAmount?: number;
  minimumAmount?: number;
  creditLimit?: number;
  show: boolean;
  onClose: VoidFunction;
  onConfirmAndProceed: (option: PAYMENT_OPTIONS) => void;
  setCreditPayment?: (value: boolean) => void;
  showCreditLimit?: boolean;
}
const PaymentDrawer = ({
  show,
  onConfirmAndProceed,
  onClose,
  creditLimit = 0,
  maximumAmount = 0,
  minimumAmount = 0,
  setCreditPayment,
  showCreditLimit = false
}: Props) => {
  const {
    t
  } = useTranslation('payments');
  const [selectedMethod, setSelectedMethod] = useState<{
    paymentId: PAYMENT_OPTIONS;
    index: number;
  }>();
  const {
    isCreditUser
  } = useContext(UserContext);
  const getRadioItems = useCallback(() => {
    const radioElements: Array<{
      radioElement: ReactElement;
      value: PAYMENT_OPTIONS;
    }> = [];
    paymentDrawerData.filter(({
      type
    }) => !(type == PAYMENT_OPTIONS.COD && minimumAmount > 0)).map(({
      title,
      desc,
      image,
      type
    }) => {
      const item = {
        radioElement: <div className="flex items-center">
              <div className="flex-1">
                <p className="text-neutral-90 text-sm">{t(title)}</p>
                <p className="text-netural-80 mt-1 text-sm">{t(desc)}</p>
              </div>

              <Image src={image} alt={t(title)} width={32} height={32} />
            </div>,
        value: type,
        isDisabled: type == PAYMENT_OPTIONS.COD && minimumAmount > 0
      };
      radioElements.push(item);
    });
    if (isCreditUser && showCreditLimit) {
      const item = {
        radioElement: <CreditLabel availableCreditLimit={creditLimit} maximumAmount={maximumAmount} />,
        value: PAYMENT_OPTIONS.CREDIT,
        isDisabled: maximumAmount >= creditLimit
      };
      radioElements.push(item);
    }
    return radioElements;
  }, [creditLimit, isCreditUser, maximumAmount, minimumAmount, showCreditLimit, t]);
  return <>
      {<RoundedBottomDrawer show={show} onClose={onClose}>
          <h5 className="mb-[22px]">{t('choose_payment_options')}</h5>
          <RadioButton radioOuterContainerStyle={{
        borderColor: COLORS.neutral10,
        borderRadius: 12,
        borderWidth: 1
      }} containerStyle={{
        gap: 16,
        paddingHorizontal: 20,
        paddingVertical: 16
      }} list={getRadioItems()} onClick={idx => {
        const selecteValue = getRadioItems()[idx].value;
        setSelectedMethod({
          paymentId: selecteValue,
          index: idx
        });
        if (selecteValue === PAYMENT_OPTIONS.CREDIT) {
          setCreditPayment?.(true);
        } else {
          setCreditPayment?.(false);
        }
      }} selected={selectedMethod?.index} />
          <Button status={selectedMethod !== undefined ? BUTTON_STATUS.ACTIVE : BUTTON_STATUS.DISABLED} onClick={() => {
        if (selectedMethod === undefined) {
          return;
        }
        onConfirmAndProceed((selectedMethod.paymentId as PAYMENT_OPTIONS));
      }} text={t('confirm_and_proceed')} size={SIZE.large} buttonStyle={{
        marginTop: 34
      }} />
        </RoundedBottomDrawer>}
    </>;
};
export default PaymentDrawer;