import formatCurrency from '@dehaat/kisan-app-bl/helpers/formatCurrency';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import creditAmountImg from '@/images/cart/credit_amount.webp';
interface Props {
  availableCreditLimit: number;
  maximumAmount: number;
}
const CreditLabel = ({
  availableCreditLimit,
  maximumAmount
}: Props) => {
  const {
    t
  } = useTranslation('cart');
  return <div className="flex items-center" data-sentry-component="CreditLabel" data-sentry-source-file="CreditLabel.tsx">
      <div className="flex-1">
        <p className="text-neutral-90 text-sm">
          {t('credit_limit_available')} -{' '}
          <span className="font-bold">
            {formatCurrency(availableCreditLimit)}
          </span>
        </p>

        {maximumAmount > availableCreditLimit && <p className="text-error-90 mt-1 text-sm">
            {t('order_value_exceeds')}
          </p>}
      </div>

      <Image src={creditAmountImg} alt={t('credit_limit_available')} width={32} height={32} data-sentry-element="Image" data-sentry-source-file="CreditLabel.tsx" />
    </div>;
};
export default CreditLabel;