import generateOtpForHyperlocalOrder from '@dehaat/kisan-app-bl/api/generateHyperlocalOrderOTP';
import validateOtpForHyperlocalOrder from '@dehaat/kisan-app-bl/api/validateHyperlocalOrderOtp';
import { FarmerDetails } from '@dehaat/kisan-app-bl/models/AddressV2';
import User from '@dehaat/kisan-app-bl/models/User';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Caption from '@/components/Caption';
import HeaderWithBackButton from '@/components/HeaderWithBackButton';
import SingleInputField from '@/components/login/SingleInputField';
import Paragraph, { VARIANT } from '@/components/Paragraph';
import Spinner from '@/components/Spinner';
import { OTPData } from '@/models/Cart';
import { secondsToMinSec } from '@/utils/helper';
interface Props {
  vendorId: string | number;
  farmerDetails?: FarmerDetails | User;
  otpData?: OTPData;
  placeHypOrder: (hascode: string) => void;
  handleClose: VoidFunction;
}
export default function HypOrderOTPValidation({
  vendorId,
  farmerDetails,
  otpData,
  placeHypOrder,
  handleClose
}: Props) {
  const {
    t
  } = useTranslation('login');
  const otpLength = 4;
  const TIMER_IN_MS = 30000;
  const [otp, setOtp] = useState(Array(otpLength).fill(''));
  const [activeIndex, setActiveIndex] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const counterRef = useRef<number>();
  const [timer, setTimer] = useState(TIMER_IN_MS / 1000);
  const {
    id: farmerId,
    full_name,
    phone_number: mobileNumber
  } = farmerDetails || {};
  const handleSendOtp = async () => {
    try {
      //setRetryOtpLoading(true)
      if (otpData && vendorId) {
        const req = {
          vendor_id: vendorId,
          ...otpData.template['sms'],
          farmer_id: farmerId
        };
        await generateOtpForHyperlocalOrder(req);
        setOtp(new Array(otpLength).fill(''));
      } else {
        setError(true);
      }
    } catch (e: any) {
      setError(e?.response?.data?.error);
    }
    // setRetryOtpLoading(false)
  };
  const triggerCounter = useCallback(() => {
    if (counterRef.current) {
      clearInterval(counterRef.current);
    }
    counterRef.current = window.setInterval(() => setTimer(prev => prev - 1), 1000);
  }, []);
  const onResendOtpBySmsClick = useCallback(async () => {
    await handleSendOtp();
    setTimer(TIMER_IN_MS / 1000);
    triggerCounter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCounter]);
  useEffect(() => {
    handleSendOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOtpError = () => {
    setError(true);
    setOtp(Array(6).fill(''));
    setActiveIndex(0);
    setLoading(false);
  };
  const handleVarifyOTP = useCallback(async (joinedOtp: string) => {
    setError(false);
    setLoading(true);
    try {
      //TODO: Integrate validation API Here
      if (farmerId) {
        const resp = await validateOtpForHyperlocalOrder({
          farmerId,
          otp: joinedOtp
        });
        if (resp) {
          const {
            hashcode
          } = resp;
          placeHypOrder(hashcode);
        }
      } else {
        throw new Error('Farmer id not found');
      }
    } catch (e: any) {
      handleOtpError();
    }
  }, [farmerId, placeHypOrder]);
  useEffect(() => {
    const handleOtp = async () => {
      handleVarifyOTP(joinedOtp);
    };
    const joinedOtp = otp.join('');
    if (Number(joinedOtp) && joinedOtp.length === otpLength) {
      handleOtp();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp, handleVarifyOTP]);
  useEffect(() => {
    if (timer <= 0 && counterRef.current) {
      clearInterval(counterRef.current);
    }
  }, [timer]);
  const onOtpSingleFieldChange = (value: string, index: number) => {
    setOtp(prev => {
      const newArray = [...prev];
      newArray[index] = value;
      return newArray;
    });
    if (value !== '') {
      setActiveIndex(prev => prev < otpLength - 1 ? prev + 1 : prev);
    }
  };
  const onPaste = useCallback((otp: string) => {
    console.log('otp pasted', otp);
    setOtp(otp.split(''));
    setActiveIndex(otpLength - 1);
  }, []);
  useEffect(() => {
    triggerCounter();
    return () => {
      if (counterRef.current) {
        clearInterval(counterRef.current);
      }
    };
  }, [triggerCounter]);
  useEffect(() => {
    if (timer <= 0 && counterRef.current) {
      clearInterval(counterRef.current);
    }
  }, [timer]);
  return <section className="h-full fixed bottom-0 left-0 w-full z-[999] bg-white" data-sentry-component="HypOrderOTPValidation" data-sentry-source-file="HypOrderOTPValidation.tsx">
      <HeaderWithBackButton onBackClick={() => handleClose()} heading={t('cart:order_code')} showHomeIcon={false} showProfileIcon={false} data-sentry-element="HeaderWithBackButton" data-sentry-source-file="HypOrderOTPValidation.tsx" />
      <div className="pt-20 px-8">
        <section className="h-full">
          <div>
            <h6>{t('cart:otp_validation')}</h6>
            <Paragraph variant={VARIANT.SMALL} className="text-neutral-70 mt-1 font-normal" data-sentry-element="Paragraph" data-sentry-source-file="HypOrderOTPValidation.tsx">
              <Trans i18nKey={'otp_info_msg'} ns="cart" values={{
              name: full_name,
              number: mobileNumber
            }} components={{
              styled: <span className="text-primary-100" />
            }} data-sentry-element="Trans" data-sentry-source-file="HypOrderOTPValidation.tsx" />

              {/* {t('order:otp_info_msg', {
                name: full_name,
                number: mobileNumber,
               })} */}
              {/* Order Code has been sent to{' '}
               <span className="text-primary-100">{full_name}</span> on phone
               number (+91 -{' '}
               <span className="text-primary-100">{mobileNumber}</span>) */}
            </Paragraph>
            <div className="mt-2">
              <p className="text-xs text-primary-100 font-medium mb-2">
                Enter OTP
              </p>
              <div className={`space-x-[15px] border  rounded-lg px-4 py-3 flex justify-center ${error ? 'border-error-100' : 'border-neutral-40'}`}>
                {Array(otpLength).fill('').map((_, index) => <SingleInputField autoFocus={index === 0} value={otp[index]} onChange={value => onOtpSingleFieldChange(value, index)} key={`otp-field-${index}`} active={activeIndex === index} onFocus={() => setActiveIndex(index)} error={error} onDelete={() => setActiveIndex(prev => prev > 0 ? prev - 1 : prev)} otpPasted={onPaste} />)}
              </div>
              {error ? <Caption label={t('otp_validation_error')} className="text-error-100 mt-2" /> : null}
            </div>
            <section className="mt-6">
              <article className="flex justify-between items-center w-full">
                <Paragraph variant={VARIANT.MEDIUM} className="text-neutral-70" data-sentry-element="Paragraph" data-sentry-source-file="HypOrderOTPValidation.tsx">
                  {t('no_otp')}
                </Paragraph>
                {timer > 0 ? <Paragraph variant={VARIANT.MEDIUM} className="text-neutral-70" isHighlighted>
                    {secondsToMinSec(timer)}
                  </Paragraph> : null}
              </article>
              <div className="mt-3">
                <Paragraph variant={VARIANT.MEDIUM} isHighlighted className="text-netural-100" data-sentry-element="Paragraph" data-sentry-source-file="HypOrderOTPValidation.tsx">
                  {t('resent_otp')}
                </Paragraph>
                <footer className="flex gap-x-4 mt-2">
                  <button className="disabled:text-neutral-40 text-primary-100 border border-neutral-20 font-nato-semibold py-2 rounded-lg text-center text-sm w-24" disabled={timer > 0} onClick={onResendOtpBySmsClick}>
                    <Paragraph isHighlighted variant={VARIANT.MEDIUM} data-sentry-element="Paragraph" data-sentry-source-file="HypOrderOTPValidation.tsx">
                      {t('by_sms')}
                    </Paragraph>
                  </button>
                </footer>
              </div>
            </section>
          </div>
        </section>
        {loading ? <Spinner /> : null}
      </div>
    </section>;
}