import fetchAllAddressV3, {
  Params,
} from '@dehaat/kisan-app-bl/api/fetchAllAddressV3'
import { Address } from '@dehaat/kisan-app-bl/models/AddressV2'
import { fixNextPageUrl } from '@dehaat/kisan-app-bl/utils/helper'
import { useCallback, useContext, useEffect, useState } from 'react'

import {
  ASSUMED_USER_NUMBER_COOKIE,
  SELECTED_ADDRESS_KEY,
  SHRI_PROGRAM_USER_DETAILS,
} from '@/constants/common'
import { AuthContext } from '@/context/AuthProvider'
import { ShriUserInLS } from '@/models/shriProgram'
import {
  getCookieValue,
  getLocalStorageKey,
  removeLocalStorageKey,
  setLocalStorageKey,
} from '@/utils/helper'

import useDebounce from './useDebounce'

const useCartAddress = () => {
  const { isAuthenticated } = useContext(AuthContext)
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null)
  const [addressList, setAddressList] = useState<Address[]>([])
  const [addressLoading, setAddressLoading] = useState(false)
  const [showAddressListModal, setShowAddressListModal] = useState(false)
  const shriUser = getLocalStorageKey<ShriUserInLS, null>(
    SHRI_PROGRAM_USER_DETAILS,
  )
  const [nextPageUrl, setNextPageUrl] = useState<string | null>('')
  const [searchQuery, setSearchQuery] = useState('')
  const [loadingNext, setLoadingNext] = useState(false)
  const [hasSavedAddress, setHasSavedAddress] = useState(false)
  const debouncedFunction = useDebounce(setSearchQuery, 500)

  const fetchNext = useCallback(async () => {
    if (nextPageUrl) {
      try {
        setLoadingNext(true)
        const { results, next } = await fetchAllAddressV3(nextPageUrl)
        setAddressList((prev) => prev.concat(...results))
        setNextPageUrl(fixNextPageUrl(next) || null)
        setLoadingNext(false)
      } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPageUrl])

  const getAllAddresses = useCallback(async () => {
    setAddressLoading(true)
    const params: Partial<Params> = {
      page_size: 20,
      search: searchQuery,
    }
    const mobileNumber = shriUser
      ? shriUser.mobileNumber
      : getCookieValue(ASSUMED_USER_NUMBER_COOKIE)
    if (mobileNumber) {
      params.phone_number = mobileNumber
    }

    let addresses: Address[] = []

    try {
      const { results, next } = await fetchAllAddressV3(undefined, params)
      addresses = results
      setNextPageUrl(fixNextPageUrl(next) || null)
      if (!searchQuery) {
        setHasSavedAddress(results.length > 0)
      }
    } catch {}

    const addressId = getLocalStorageKey<string, null>(
      SELECTED_ADDRESS_KEY,
      null,
    )
    if (addressId) {
      const currentAddress = addresses.find(
        (address) => address.id === Number(addressId),
      )
      if (currentAddress) {
        setSelectedAddress(currentAddress)
      } else {
        setSelectedAddress(null)
        removeLocalStorageKey(SELECTED_ADDRESS_KEY)
      }
    }
    setAddressList(addresses)
    if (!addresses.length && !searchQuery) {
      setShowAddressListModal(false)
    }
    setAddressLoading(false)
  }, [searchQuery, shriUser?.mobileNumber])

  const updateSelectedAddress = useCallback((address: Address | null) => {
    setSelectedAddress(address)
    if (address) {
      setLocalStorageKey(SELECTED_ADDRESS_KEY, address.id)
    } else {
      removeLocalStorageKey(SELECTED_ADDRESS_KEY)
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      if (shriUser?.address?.id && shriUser.allowChangeAddress === false) {
        setSelectedAddress(shriUser.address as Address)
      } else {
        getAllAddresses()
      }
    }
    // Disabled due to ShriCard.address, since it is causing multiple rerendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    getAllAddresses,
    shriUser?.address?.id,
    shriUser?.allowChangeAddress,
  ])

  return {
    addressList,
    addressLoading,
    getAllAddresses,
    selectedAddress,
    setShowAddressListModal,
    showAddressListModal,
    setSelectedAddress,
    updateSelectedAddress,
    setSearchQuery: debouncedFunction,
    hasSavedAddress,
    fetchNext,
    loadingNext,
  }
}

export default useCartAddress
